import React, { useEffect, useState } from "react";
import { RiCheckLine } from "react-icons/ri";
import { VscDashboard } from "react-icons/vsc";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "./logo512.jpg";
import "./Navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaBookMedical } from "react-icons/fa";
import { SiSalesforce } from "react-icons/si";
import { GiBuyCard } from "react-icons/gi";
import { SiNextbilliondotai } from "react-icons/si";
import { FaRProject } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa";
import { PiExamDuotone } from "react-icons/pi";
import { TbLogout } from "react-icons/tb";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { url } from "../link";
function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const nav = useNavigate();
  const [s, sets] = useState(false);
  const [date, setdate] = useState("");

  useEffect(() => {
    axios.post(url + "/edusphere/getprojectdate").then((res) => {
      var n = new Date();
      var today =
        n.getFullYear() +
        "-" +
        String(n.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(n.getDate()).padStart(2, "0");
      setdate(res.data);

      if (today === res.data) {
        sets(true);
      } else {
        sets(false);
      }
    });
    if (window.localStorage.getItem("user") === null) {
      nav("/login");
    }
  }, []);
  const logout = () => {
    window.localStorage.removeItem("user");
    alert("Logout Successfully");
    nav("/");
  };

  return (
    <>
      {window.localStorage.getItem("user") !== null ? (
        <div>
          <div className="navbar ">
            {/* <button >click</button> */}

            <GiHamburgerMenu onClick={showSidebar} size="50" color="white" />

            <span
              className="topbar-title"
              style={{ color: "white", fontSize: "1em" }}
            >
              Edu Sphere-
              {!s ? (
                <span
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => {
                    nav("/changedate");
                  }}
                >
                  {date}
                </span>
              ) : (
                <span style={{ color: "green" }}>{date}</span>
              )}
            </span>
            <img
              src={logo}
              height="50px"
              width="50px"
              className="rounded-circle"
              onClick={logout}
            />
          </div>
          <nav
            className={sidebar ? "nav-menu active" : "nav-menu"}
            style={{ zIndex: 9999, height: "150vh" }}
          >
            <ul className="nav-menu-items">
              <li className="navbar-toggle">
                <IoMdClose onClick={showSidebar} size="50" color="white" />
              </li>
              <li className="nav-text">
                <NavLink to="/dashboard">
                  <VscDashboard />
                  <span>Dashboard</span>
                </NavLink>
              </li>

              <li className="nav-text">
                <NavLink className="dropdown-toggle" data-bs-toggle="dropdown">
                  <SiSalesforce />
                  <span className="span">Enquiry</span>
                  <span className="arrow"></span>
                </NavLink>
                <ul
                  className="dropdown-menu "
                  style={{ backgroundColor: "blue" }}
                >
                  <li>
                    <NavLink to="/enquiryentry">
                      <span className="span">Enquiry Entry</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/showenquiry">
                      <span className="span">View Enquiry</span>
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/viewmobileenquiry">
                      <span className="span">View Mobile Enquiry</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/addmobileenquiry">
                      <span className="span">Mob Enquiry</span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-text">
                <NavLink className="dropdown-toggle" data-bs-toggle="dropdown">
                  <GiBuyCard />
                  <span className="span">Registration</span>
                  <span className="arrow"></span>
                </NavLink>
                <ul
                  className="dropdown-menu "
                  style={{ backgroundColor: "blue" }}
                >
                  <li>
                    <NavLink to="/addregistration">
                      <span className="span">Add Reg</span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/oldregistration">
                      <span className="span">Old Reg</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/viewregistration">
                      <span className="span">Reg report</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/coursechange">
                      <span className="span">Coursechange</span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/pendingbal">
                      <span className="span">Balance Pending</span>
                    </NavLink>
                  </li> */}
                </ul>
              </li>

              <li className="nav-text">
                <NavLink className="dropdown-toggle" data-bs-toggle="dropdown">
                  <SiNextbilliondotai />
                  <span className="span">Bill</span>
                  <span className="arrow"></span>
                </NavLink>
                <ul
                  className="dropdown-menu "
                  style={{ backgroundColor: "blue" }}
                >
                  <li>
                    <NavLink className="nav-link" to="/viewbill">
                      <span className="span">View Bill</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="nav-link" to="/addbill">
                      <span className="span">Add Bill</span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-text">
                <NavLink className="dropdown-toggle" data-bs-toggle="dropdown">
                  <FaRProject />
                  <span className="span">Project</span>
                  <span className="arrow"></span>
                </NavLink>
                <ul
                  className="dropdown-menu "
                  style={{ backgroundColor: "blue" }}
                >
                  <li>
                    <NavLink to="/addproject2023">
                      <span className="span">Addproject</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/viewproject2023">
                      <span className="span">Viewproject</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/viewlaptop">
                      <span className="span">Viewlaptop</span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-text">
                <NavLink className="dropdown-toggle" data-bs-toggle="dropdown">
                  <FaBookMedical />
                  <span className="span">Course</span>
                  <span className="arrow"></span>
                </NavLink>
                <ul
                  className="dropdown-menu "
                  style={{ backgroundColor: "blue" }}
                >
                  <li>
                    <NavLink to="/addcourse">
                      <span className="span">Addcourse</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/viewcourse">
                      <span className="span">Viewcourse</span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-text">
                <NavLink className="dropdown-toggle" data-bs-toggle="dropdown">
                  <FaQuestion />
                  <span className="span">Question</span>
                  <span className="arrow"></span>
                </NavLink>
                <ul
                  className="dropdown-menu "
                  style={{ backgroundColor: "blue" }}
                >
                  <li>
                    <NavLink to="/addsubject">
                      <span className="span">Books</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/addquestion">
                      <span className="span">Addquestion</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/viewquestion">
                      <span className="span">Viewquestion</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/bulkquestion">
                      <span className="span">Bulbquestion</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-text">
                <NavLink className="dropdown-toggle" data-bs-toggle="dropdown">
                  <PiExamDuotone />
                  <span className="span">Exam</span>
                  <span className="arrow"></span>
                </NavLink>
                <ul
                  className="dropdown-menu "
                  style={{ backgroundColor: "blue" }}
                >
                  <li>
                    <NavLink to="/scheduleexam">
                      <span className="span">Schedule</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/viewattendanceclosed">
                      <span className="span">Attendance end</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/viewexam">
                      <span className="span">View</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/attendexam">
                      <span className="span">Attend</span>
                    </NavLink>
                  </li>
                  {/*                 <li>
                  <NavLink to="/viewsubject">
                    <span className="span">View Subject</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/addsubject">
                    <span className="span">Add Subject</span>
                  </NavLink>
                </li> */}
                </ul>
              </li>
              <li className="nav-text">
                <NavLink to="/viewtodayattendance">
                  <RiCheckLine />
                  <span className="span">Attendance</span>
                </NavLink>
              </li>
              <li className="nav-text">
                <NavLink to="/addstaffattendance">
                  <RiCheckLine />
                  <span className="span">Staff Attendance</span>
                </NavLink>
              </li>
              <li className="nav-text">
                <span
                  onClick={logout}
                  style={{ color: "white", cursor: "pointer" }}
                >
                  <TbLogout />
                  <span className="span">Logout</span>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Navbar;
